<template>
  <div class="container-fluid leaderboard">
    <h1 class="text-center">LEADERBOARD</h1>

    <div class="leaderboard-tabs mt-5">
      <b-tabs v-model="selectedTab" fill>
        <b-tab title="ALL TIME" active>
          <div class="content">
            <div class="items"
                 v-if="allTime.data && allTime.data.length > 0">
              <leaderboard-item v-for="(item, index ) in allTime.data"
                                :type="'all'"
                                :display="'page'"
                                :item="item"
                                :key="index"
              />
            </div>
            <div class="no-result d-flex justify-content-center" v-else>
              Unavailable
            </div>
          </div>
        </b-tab>
        <b-tab title="MONTHLY">
          <div class="content">
            <div class="items"
                 v-if="monthly.data && monthly.data.length > 0">
              <leaderboard-item v-for="(item, index ) in monthly.data"
                                :type="'monthly'"
                                :display="'page'"
                                :item="item"
                                :key="index"
              />
            </div>
            <div class="no-result d-flex justify-content-center" v-else>
              Unavailable
            </div>
          </div>
        </b-tab>
        <b-tab title="WEEKLY">
          <div class="content">
            <div class="items"
                 v-if="weekly.data && weekly.data.length > 0">
              <leaderboard-item v-for="(item, index ) in weekly.data"
                                :type="'weekly'"
                                :display="'page'"
                                :item="item"
                                :key="index"
              />
            </div>
            <div class="no-result d-flex justify-content-center" v-else>
              Unavailable
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <div class="pagination-container mt-3">
      <pagination class="leaderboard-pagination b-pagination pagination-sm"
                  v-if="selectedTab === 0 && allTime.data"
                  :data="allTime"
                  :show-disabled="true" @pagination-change-page="getAllTime">
      </pagination>
      <pagination class="leaderboard-pagination b-pagination pagination-sm"
                  v-if="selectedTab === 1 && monthly.data"
                  :data="monthly"
                  :show-disabled="true" @pagination-change-page="getMonthly">
      </pagination>
      <pagination class="leaderboard-pagination b-pagination pagination-sm"
                  v-if="selectedTab === 2 && weekly.data"
                  :data="weekly"
                  :show-disabled="true" @pagination-change-page="getWeekly">
      </pagination>
    </div>
  </div>
</template>
<style lang="scss">
  @import "../../assets/scss/components/user/leaderboard";
</style>
<script>
  import LeaderboardMixin from '@/mixins/LeaderboardMixin';
  const LeaderboardItem = () =>
  import('@/components/user/leaderboard/LeaderboardItem.vue');


  export default {
    name : 'Leaderboard',
    data() {
      return {
        selectedTab : 0,
      }
    },
    components : {
      LeaderboardItem,
    },
    mixins : [
      LeaderboardMixin,
    ],
    mounted() {
      this.$analytics.fbq.event('PageView', {
        'page'     : 'leaderboards',
        'platform' : 'website',
        'user_id'  : this.$store.getters['user/user'].user_id,
      });
    }
  }

</script>